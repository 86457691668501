import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { LoginContext } from "./LogIn.js";

const NewUpdate = ({updateModalState, setTriggerFetch, selectedTicketData,ticketUpdatesData}) => {

    const [loading, setLoading] = useState(true);
    const [currentGroupData, setCurrentGroupData] = useState(); //this is pulled automatically from api
    const [destinationGroupData, setDestinationGroupData] = useState([]);
    const [updateTypeData, setUpdateTypeData] = useState([]);
    const [closureCategoryData, setClosureCategoryData] = useState([]);
    const [rootCauseData, setRootCauseData] = useState([]);
    const [runbookCategoryData, setRunbookCategoryData] = useState([]);

    const [updateErrorsResp, setUpdateErrorsResp] = useState([]);


    const [timeUpdatedSelection, setTimeUpdatedSelection] = useState('');
    const [destinationGroupSelection, setDestinationGroupSelection] = useState('');
    const [updateTypeSelection, setUpdateTypeSelection] = useState('');
    const [notesSelection, setNotesSelection] = useState('');
    const [closureCategorySelection,setClosureCategorySelection] = useState(selectedTicketData[0].ClosureCategoryID != null ? selectedTicketData[0].ClosureCategoryID : ''); // we leave this null if not being sent back to initiator
    const [rootCauseSelection,setRootCauseSelection] = useState(selectedTicketData[0].RootCauseID != null ? selectedTicketData[0].RootCauseID : ''); // we leave this null if not being sent back to initiator
    const [runbookCategorySelection, setRunbookCategorySelection] = useState('');


    const { getSession } = useContext(LoginContext);

    const postNewUpdate = async (ticketId, data) => {
        try{
            console.log("new update data: ", data);
            const url = `https://api.jlr-ops-incident-tracker.com/api/tickets/${ticketId}/updates`;
            const currentSession = await getSession(); //check to see if still logged in
            const accessToken = currentSession['accessToken']['jwtToken'] //pull token
            const resp = await axios.post(url, data,{headers:{'Authorization':accessToken}});
            console.log(resp.data);
            setUpdateErrorsResp([]);
            alert('Update created.');
        } catch (err) {
            console.log(err);
            setUpdateErrorsResp(err.response.data.errors);
            alert('Failed to Update the ticket. See information below.');
            return (err.response.data.errors)

        }

    }


    const fetchUtilityDataCall = async (endpoint) => {
        const url = `https://api.jlr-ops-incident-tracker.com/api/utilities/${endpoint}`;
        const currentSession = await getSession(); //check to see if still logged in
        const accessToken = currentSession['accessToken']['jwtToken'] //pull token
        const response = await axios.get(url, {headers:{'Authorization':accessToken}});
        console.log("utility Data: ", response.data[0]);
        setLoading(false);
        return await response.data[0];
    };

    const fetchCurrentGroup = async (ticketid) => {
        const currentGroupDataResp = await fetchUtilityDataCall(`currentdispatcher/${ticketid}`);
        setCurrentGroupData(currentGroupDataResp[0]['GroupsID']);
    }

    const fetchDestinationGroups = async (ticketid) => {
        const destinationGroupDataResp = await fetchUtilityDataCall(`eligibledestination/${ticketid}`);
        console.log(destinationGroupDataResp);
        setDestinationGroupData(destinationGroupDataResp);
    }

    const fetchUpdateTypeGroups = async (ticketid) => {
        const updateTypeDataResp = await fetchUtilityDataCall(`updatetypes/${ticketid}`);
        console.log(updateTypeDataResp);
        setUpdateTypeData(updateTypeDataResp);
    }

    const fetchClosureCategories = async () => {
        const closureCategoryDataResp = await fetchUtilityDataCall(`closurecategories`);
        console.log(closureCategoryDataResp);
        setClosureCategoryData(closureCategoryDataResp);
    }

    const fetchRunbookCategories = async () => {
        const RunbookCategoryDataResp = await fetchUtilityDataCall(`runbooks/1`);
        console.log(RunbookCategoryDataResp);
        setRunbookCategoryData(RunbookCategoryDataResp);
    }

    const fetchRootCause = async () => {
        const rootCauseDataResp = await fetchUtilityDataCall('rootcauses');
        setRootCauseData(rootCauseDataResp);
    }

    function addZero(i) {
        if (i < 10) {i = "0" + i}
        return i;
    }

    const verifyDatetimeString = async (input) => {
        const d  = new Date();
        const seconds = addZero(d.getSeconds());
        const dateTimeString = new Date(input+":"+seconds.toString()).toISOString().replace("T", " ").replace(".000Z","");
        return dateTimeString;
    }

    const getLoggedInUsername = async () => {
        const currentSession = await getSession();
        const username = await currentSession['idToken']['payload']['cognito:username'];
        return username;
    };

    const onSubmitHandler = async (e) => {

        e.preventDefault(); //stops it from auto refreshing on form submission - i guess we could leave it as default but it feels less seamless.
        const updateData = {
            LatestUpdateID: `${ticketUpdatesData.slice(-1)[0]['TicketUpdatesID'].toString()}`,
            DispatchGroupID: `${currentGroupData}`,
            DestinationGroupID: `${destinationGroupSelection}`,
            HandledByUserID: `${await getLoggedInUsername()}`,
            UpdateTypeID: `${updateTypeSelection}`,
            TimeOfUpdate: `${await verifyDatetimeString(timeUpdatedSelection)}`,
            DispatchNotes: `${notesSelection}`,
            ClosureCategoryID: `${closureCategorySelection}`, //if this is not selected it'll still post as its an optional field. Might need to patch this.
            RootCauseID: `${rootCauseSelection}`,
            RunbookID: `${runbookCategorySelection}`
        };

        const resp = await postNewUpdate(ticketUpdatesData.slice(-1)[0]['TicketsID'].toString(),updateData);
        if (resp === undefined || resp.length === 0) {
            setTriggerFetch(true);
            updateModalState();
        }
    };

    useEffect(
        () => {
            fetchCurrentGroup(ticketUpdatesData.slice(-1)[0]['TicketsID'].toString());
            fetchDestinationGroups(ticketUpdatesData.slice(-1)[0]['TicketsID'].toString());
            fetchUpdateTypeGroups(ticketUpdatesData.slice(-1)[0]['TicketsID'].toString());
            fetchClosureCategories();
            fetchRunbookCategories();
            fetchRootCause();
        },[]);
    
    useEffect(
        () => {
            console.log("error resp useeffect: " + JSON.stringify(updateErrorsResp));
        },[updateErrorsResp]);

    return (
        <div class="items-center justify-center p-12">
          <div>
            <form class="px-4 max-w-3xl mx-auto space-y-4" onSubmit={onSubmitHandler}>
                <div>
                <h1 className="text-3xl font-semibold leading-6 text-white">Updating Ticket:</h1>
                <p class="text-white italic font-thin py-4 font-semibold">"{selectedTicketData[0].IncidentIdentifier} : {selectedTicketData[0].VIN}"</p>
                <p class="text-white font-semibold">Last Update logged at: {new Date(ticketUpdatesData.slice(-1)[0]['TimeOfUpdate']).toString()}</p>
                <p className="mt-1 text-sm text-white">Complete the form to add an update to ticket.</p>
                </div>
                    <div class="flex space-x-4">
                    <div>
                        <label htmlFor="ToArr" className="block text-sm font-medium text-white">
                            Time Of Update*
                        </label>
                        <input type="datetime-local" name="TimeOfUpdate" id="TimeofUpdate" value={timeUpdatedSelection} onChange={(e) => {setTimeUpdatedSelection(e.target.value); console.log(e.target.value)}} className="my-2 focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md"/>    
                    </div>
                    </div>
                    <div class="flex space-x-4">
                        <div class="">
                            <label htmlFor="Destination Group" className="block text-sm font-medium text-white">Destination Group*</label>
                            <select disabled={loading} name="DestinationGroupID" id="DestinationGroupID" value={destinationGroupSelection} onChange={(e) => {setDestinationGroupSelection(e.target.value); console.log(e.target.value)}}  className="my-2 focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md">
                                <option></option>
                                {destinationGroupData.map(item => (<option key={item.GroupsID} value={item.GroupsID}>{item.GroupName}</option>))}
                            </select>
                        </div>
                        </div>
                        <div class="flex space-x-4">
                        <div class="">
                            <label htmlFor="Update Type" className="block text-sm font-medium text-white">Update Type*</label>
                            <select disabled={loading} name="UpdateTypeID" id="UpdateTypeID" value={updateTypeSelection} onChange={(e) => {setUpdateTypeSelection(e.target.value); console.log(e.target.value)}} className="my-2 focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md">
                                <option></option>
                                {updateTypeData.map(item => (<option key={item.UpdateTypesID} value={item.UpdateTypesID}>{item.UpdateTypeName}</option>))}
                            </select>
                        </div>
                        </div>


                        {((selectedTicketData[0].InitiatorGroupID == destinationGroupSelection)) &&<div class="">
                        <div class="">
                            <label htmlFor="RunbookSelection" className="block text-sm font-medium text-white">Runbook</label>
                            <select disabled={loading} name="RunbookSelection" id="RunbookSelectionID" value={runbookCategorySelection} onChange={(e) => {setRunbookCategorySelection(e.target.value)}} className="my-2 focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md">
                                <option></option>
                                {runbookCategoryData.map(item => (<option key={item.RunbookID} value={item.RunbookID}>{item.RunbookName}</option>))}     
                            </select>
                        </div>
                    </div>}



                    <div>
                        <label htmlFor="Notes" className="block text-sm font-medium text-white">
                            Notes
                            </label>
                            <textarea id="Notes" name="Notes" onChange={(e) => {setNotesSelection(e.target.value); console.log(e.target.value)}}  rows="3" cols="55" className="my-2 focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md resize-none"/>
                    </div>

                    
                

                    {((selectedTicketData[0].InitiatorGroupID == destinationGroupSelection) || (currentGroupData == '5' && destinationGroupSelection == '6')) &&<div class="">
                        <div class="">
                            <label htmlFor="ClosureCategory" className="block text-sm font-medium text-white">Closure Category*</label>
                            <select disabled={loading} name="ClosureCategory" id="ClosureCategoryID" value={closureCategorySelection} onChange={(e) => {setClosureCategorySelection(e.target.value); console.log(e.target.value)}} className="my-2 focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md">
                                <option></option>
                                {closureCategoryData.map(item => (<option key={item.ClosureCategoryID} value={item.ClosureCategoryID}>{item.ClosureCategoryName}</option>))}     
                            </select>
                        </div>
                    </div>}


                    {((selectedTicketData[0].InitiatorGroupID == destinationGroupSelection) || (currentGroupData == '5' && destinationGroupSelection == '6')) &&<div class=""> 
                        <div class="">
                            <label htmlFor="RootCause" className="block text-sm font-medium text-white">Root Cause*</label>
                            <select disabled={loading} name="RootCause" id="RootCauseID" value={rootCauseSelection} onChange={(e) => {setRootCauseSelection(e.target.value); console.log(e.target.value)}} className="my-2 focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md">
                                <option></option>
                                {rootCauseData.map(item => (<option key={item.RootCauseID} value={item.RootCauseID}>{item.Name}</option>))}     
                            </select>
                        </div>
                    </div>}


                    <button type="submit" class="p-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Submit</button>
                    <button class="p-2 mx-8 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" onClick={updateModalState}>Cancel</button>
                    </form>
                    {updateErrorsResp.length != 0 && <div class="text-red-500">{JSON.stringify(updateErrorsResp)}</div>}
                    </div>
        </div>
      );
    };

export default NewUpdate;

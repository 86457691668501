import { useMemo, useState } from "react"
import { useFlexLayout, useAbsoluteLayout, useSortBy, useTable } from "react-table"
const Table = (props) => {

  const [selectedRow, setSelectedRow] = useState();

  const dateFormatter = (datestring) => {

    if (datestring === null) {
      return ""
    } else {

      const date = new Date(datestring);
      const dateString = date.toISOString().split('T')[0];
      const timeString = date.toISOString().split('T')[1].split('.')[0];
      return (dateString + " " + timeString);

    }
  };

  function addZero(i) {
    if (i < 10) {i = "0" + i}
    return i;
  }


  // Time Formatter function to format the E2ETimeElapsed 

  const timeFormatter = (timeString) => {
    if (timeString === null || timeString === "") {
      timeString = "00:00:00"
    }
    const time = timeString.slice(0, -3);
    const hour = parseInt(time.substring(0, time.indexOf(':')));
    const days = Math.floor(hour/24);
    const hours = Math.floor(hour%24);
    return (addZero(days) + ":" + addZero(hours) + ":" + time.slice(-2));
    
  };




  var time_difference;

  // takes in arrival time of ticket (has weird format) so will need to be converted
  function SLA_CountDown(E2E_Time_Elapsed, Ticket_Source) {

    // Can be read as 3 hours, 24 hours, 72 hours and 120 hours
    const SLA_Band2 = 3 * 1000 * 60 * 60
    const SLA_Band3 = 24 * 1000 * 60 * 60
    const SLA_Band4 = 72 * 1000 * 60 * 60
    const CEC_SLA_Band5 = 120 * 1000 * 60 * 60
    const RTS_SLA_Band5 = 168 * 1000 * 60 * 60
    const RTS_SLA_Band6 = 240 * 1000 * 60 * 60

        

        const timeFormatter = (timeString) => {
          if (timeString === null) {
            timeString = '00:00:00'
          }
          const time = timeString.slice(0, -3);
          const hour = parseInt(time.substring(0, time.indexOf(':')));
          const days = Math.floor(hour/24);
          const hours = Math.floor(hour%24);
          return (addZero(days) + ":" + addZero(hours) + ":" + time.slice(-2));
          
        };
        
        var E2E_Time_Elapsed_Date = timeFormatter(E2E_Time_Elapsed);
        var E2E_Day = (E2E_Time_Elapsed_Date.slice(0, 2)) * 24 * 60 * 60 * 1000;
        var E2E_Hour = (E2E_Time_Elapsed_Date.slice(3, 5)) * 60 * 60 * 1000;
        var E2E_Minute = (E2E_Time_Elapsed_Date.slice(6, 8)) * 60 * 1000;
        var E2E_milliseconds = E2E_Day + E2E_Hour + E2E_Minute;
        

         // variable used to help identify which if statement the program will follow (prevents only the first if statement being run)
        var count = 0;
        // boolean variable, will be true when ticket hits final SLA band
        var final_bracket_reached = false;

        // First if statement defines sets the time_difference variable for the first time
        if (Ticket_Source === 'TA') {
          
          if (count === 0) {
            time_difference = SLA_Band2 - E2E_milliseconds
            count += 1
          } 
          if (time_difference < 0 && count === 1) {
              time_difference = SLA_Band3 - E2E_milliseconds
              count += 1
          } 
          if (time_difference < 0 && count === 2) {
              time_difference = SLA_Band4 - E2E_milliseconds
              count += 1
          }
          if (time_difference < 0 && count === 3) {
              time_difference = RTS_SLA_Band5 - E2E_milliseconds
              count += 1
          }
          if (time_difference < 0 && count === 3) {
            time_difference = RTS_SLA_Band6 - E2E_milliseconds
            count += 1
          }
          if (time_difference < 0 && count === 4) {
              final_bracket_reached = true
          }

        } else {

          if (count === 0) {
            time_difference = SLA_Band2 - E2E_milliseconds
            count += 1
          } 
          if (time_difference < 0 && count === 1) {
              time_difference = SLA_Band3 - E2E_milliseconds
              count += 1
          } 
          if (time_difference < 0 && count === 2) {
              time_difference = SLA_Band4 - E2E_milliseconds
              count += 1
          }
          if (time_difference < 0 && count === 3) {
              time_difference = CEC_SLA_Band5 - E2E_milliseconds
              count += 1
          }
          if (time_difference < 0 && count === 4) {
              final_bracket_reached = true
          }
        }
        
        // Time calculations to convert from milliseconds to days, hours, minutes and seconds
        var days = Math.floor(time_difference / (1000 * 60 * 60 * 24));
        var hours = Math.floor((time_difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((time_difference % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((time_difference % (1000 * 60)) / 1000);

        // stores the remaining time in days/hours/minutes/seconds format
        var result = days + "d " + hours + "h " + minutes + "m " + seconds + "s ";
  
        // If not yet in Final SLA band, display the countdown timer
        if (final_bracket_reached === false) {
          return result;
        // If in final SLA band, no need to show timer as it won't move any further
      } else {
          return 'Final SLA Bracket Reached!'
      }
  };




  function hour_calc(time_string) {
    if (time_string === null) {
      time_string = "00:00:00"
    }
    var result = time_string.substring(0, time_string.indexOf(':'));
    result = Number(result);
    return result
  }

 






  const changeColour = selectedRow => {
    if (selectedRow !== undefined){
      setSelectedRow(selectedRow);
    };
  };
    const data = useMemo(
        () => props.data,
        [props.data]);

    const columns = useMemo(
        () => [
          {
            Header: 'Ticket ID',
            accessor: 'TicketsID', // accessor is the "key" in the data
          },
          {
            Header: 'Emerging Issue Flag',
            accessor: 'EmergingIssueFlag',
          },
          {
            Header: 'Inc. ID',
            accessor: 'IncidentIdentifier',
            width: `w-1/6`,
          },
          {
            Header: 'Country',
            accessor: 'CountryISO3',
            width: `w-1/6`,
          },
          {
            Header: 'Type',
            accessor: 'TicketSource',
            width: `w-1/6`,
          },
          {
            Header: 'Source Group',
            accessor: 'InitiatorGroup',
            width: `w-1/5`,
          },
          {
            Header: 'Current Location',
            accessor: 'AssigneeGroup',
            width: `w-1/6`,
          },
          {
            Header: 'Priority',
            accessor: 'PriorityName',
            width: `w-1/6`,
          },
          {
            Header: 'VIN',
            accessor: 'VIN',
            width: `w-1/5`,
          },
          {
            Header: 'Category',
            accessor: 'CategoryName',
            width: `w-1/6`,
          },
          {
            Header: 'Date Logged',
            accessor: d => {return dateFormatter(d.TimeOfArrival)},
            width: `w-1/5`,
          },          
          {
            Header: 'E2E Time Elapsed',
            accessor: d => {return timeFormatter(d.E2ETimeElapsed)},
            Cell: ({row}) => {
              const rowData = row.original;
              if (rowData.InitiatorGroup === 'CEC' && hour_calc(rowData.E2ETimeElapsed) < 3) {
                return <span style={{background: "MediumSeaGreen",color:'white'}}>{timeFormatter(rowData.E2ETimeElapsed)}</span>
              }
              if (rowData.InitiatorGroup === 'CEC' && hour_calc(rowData.E2ETimeElapsed) >= 3 && hour_calc(rowData.E2ETimeElapsed) < 24) {
                return <span style={{background: "Green",color:'white'}}>{timeFormatter(rowData.E2ETimeElapsed)}</span>
              }
              if (rowData.InitiatorGroup === 'CEC' && hour_calc(rowData.E2ETimeElapsed) >= 24 && hour_calc(rowData.E2ETimeElapsed) < 72) {
                return <span style={{background: "#FEBE10",color:'black'}}>{timeFormatter(rowData.E2ETimeElapsed)}</span>
              }
              if (rowData.InitiatorGroup === 'CEC' && hour_calc(rowData.E2ETimeElapsed) >= 72 && hour_calc(rowData.E2ETimeElapsed) < 120) {
                return <span style={{background: "DarkOrange",color:'white'}}>{timeFormatter(rowData.E2ETimeElapsed)}</span>
              }
              if (rowData.InitiatorGroup === 'CEC' && hour_calc(rowData.E2ETimeElapsed) >= 120) {
                return <span style={{background: "FireBrick",color:'white'}}>{timeFormatter(rowData.E2ETimeElapsed)}</span>
              }
              if (rowData.InitiatorGroup !== 'CEC' && hour_calc(rowData.E2ETimeElapsed) < 3) {
                return <span style={{background: "MediumSeaGreen",color:'white'}}>{timeFormatter(rowData.E2ETimeElapsed)}</span>
              }
              if (rowData.InitiatorGroup !== 'CEC' && hour_calc(rowData.E2ETimeElapsed) >= 3 && hour_calc(rowData.E2ETimeElapsed) < 24) {
                return <span style={{background: "Green",color:'white'}}>{timeFormatter(rowData.E2ETimeElapsed)}</span>
              }
              if (rowData.InitiatorGroup !== 'CEC' && hour_calc(rowData.E2ETimeElapsed) >= 24 && hour_calc(rowData.E2ETimeElapsed) < 72){
                return <span style={{background: "#FFC72C",color:'white'}}>{timeFormatter(rowData.E2ETimeElapsed)}</span>
              }
              if (rowData.InitiatorGroup !== 'CEC' && hour_calc(rowData.E2ETimeElapsed) >= 72 && hour_calc(rowData.E2ETimeElapsed) < 168) {
                return <span style={{background: "DarkOrange",color:'white'}}>{timeFormatter(rowData.E2ETimeElapsed)}</span>
              }
              if (rowData.InitiatorGroup !== 'CEC' && hour_calc(rowData.E2ETimeElapsed) >= 168 && hour_calc(rowData.E2ETimeElapsed) < 240) {
                return <span style={{background: "Crimson",color:'white'}}>{timeFormatter(rowData.E2ETimeElapsed)}</span>
              }
              if (rowData.InitiatorGroup !== 'CEC' && hour_calc(rowData.E2ETimeElapsed) >= 240) {
                return <span style={{background: "FireBrick",color:'white'}}>{timeFormatter(rowData.E2ETimeElapsed)}</span>
              }
              else{
                return <span >{rowData.E2ETimeElapsed}</span>
              }
            },
            width: `w-1/5`,
          },
          {
            Header: 'SLA Bracket CountDown',
            accessor: d => {return SLA_CountDown(d.E2ETimeElapsed, d.TicketSource)},
            width: `w-1/4`,
          },
          {
            Header: 'Status',
            accessor: 'Status',
            width: `w-1/6`,
          },
        ],
        []
      )


      const tableInstance = useTable({ columns, data, initialState: {hiddenColumns: ["TicketsID","EmergingIssueFlag"]} }, useSortBy,);
      const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
      } = tableInstance;
      

      return (
        // apply the table props
        <table class="text-left w-screen h-screen"{...getTableProps()}>
          <thead class="bg-gray-600 flex text-white w-screen">
            {// Loop over the header rows
            headerGroups.map(headerGroup => (
              // Apply the header row props
              <tr class="flex w-screen" {...headerGroup.getHeaderGroupProps()}>
                {// Loop over the headers in each row
                headerGroup.headers.map(column => (
                  // Apply the header cell props
                  <th class={`p-4 ${column.width}`}{...column.getHeaderProps(column.getSortByToggleProps())}>
                    {// Render the header
                    column.render('Header')}
                    <span>
                      {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
            {/* Apply the table body props */}
            <tbody class="bg-grey-light flex flex-col items-center overflow-auto w-screen h-1/2"{...getTableBodyProps()}>
            {// Loop over the table rows
            rows.map((row) => {
              // console.log(row.values)
              // Prepare the row for display
              prepareRow(row)
              return (
                // Apply the row props
                //Also pulling the clicked row value using OnClick event. TODO: Need to write a handler to save the state for the relevant info.
                <tr class={`flex w-full hover:bg-blue-100 border-2 ${selectedRow === row.values.TicketsID ? `bg-gray-300` : ``}`} id={row.values.TicketsID} {...row.getRowProps()} onClick={() => {changeColour(row.values.TicketsID);props.onTicketSelectHandler(row);}} >
                  {// Loop over the rows cells
                  row.cells.map(cell => {
                    // console.log(cell.value)
                    // Apply the cell props
                    return (
                      <td class={`px-4 ${cell.column.width} ${row.values.EmergingIssueFlag === 1 ? `bg-yellow-200` : ``} hover:bg-blue-500 truncate ...`} {...cell.getCellProps()}>
                        {// Render the cell contents
                        
                        cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      )
}

export default Table;
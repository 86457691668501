import React, { useState, useContext } from "react";
import { LoginContext } from "./LogIn.js";
import logo from '../MicrosoftTeams-image (8).png';

const LoginForm = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const { handleLogin } = useContext(LoginContext);

  const onSubmitHandler = (e) => {
    e.preventDefault();
    handleLogin(username, password);
  };

  return (
    <div class="relative flex flex-col justify-center min-h-screen overflow-hidden">
      <div class="w-full p-6 m-auto bg-white border-t border-grey-600 rounded shadow-lg shadow-blue-800/50 lg:max-w-md">
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img src={logo} alt="Logo" style={{ width: '50%', height: 'auto', borderRadius:'50%' }} />
        </div>
        <h1 class="text-3xl font-semibold text-center text-blue-700">
          TICKET TRACKER
        </h1>
        <form class="mt-6" onSubmit={onSubmitHandler}>
          <div>
            <label for="text" class="block text-sm text-blue-800">
              Username
            </label>
            <input
              type="text"
              class="block w-full px-4 py-2 mt-2 text-blue-700 bg-white border rounded-md focus:border-blue-400 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
              value={username}
              onChange={(e) => {
                setUsername(e.target.value);
              }}
            />
          </div>
          <div class="mt-4">
            <div>
              <label for="password" class="block text-sm text-blue-800">
                Password
              </label>
              <input
                type="password"
                class="block w-full px-4 py-2 mt-2 text-blue-700 bg-white border rounded-md focus:border-blue-400 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </div>
            <p class="text-xs text-gray-600 hover:underline">
              Forget Password? Contact your admin.
            </p>
            <div class="mt-6">
              <button
                type="submit"
                class="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-blue-700 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
              >
                Login
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;

import React, { createContext, useState, useEffect } from "react";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import UserPool from "./UserPool";

const LoginContext = createContext();

const Login = (props) => {
  const [user, setUser] = useState();
  const [userAttr, setUserAttr] = useState();

  const getSession = async () => {
    return await new Promise((resolve, reject) => {
      //this assumes users already logged in
      const user = UserPool.getCurrentUser();
      console.log(user);
      //if user exists
      if (user) {
        user.getSession((err, session) => {
          if (err) {
            //if user is not currently logged in
            console.log(err);
            props.setIsLoggedIn(false);
            reject();
          } else {
            //if user is currently logged in
            props.setIsLoggedIn(true);
            console.log(session);
            resolve(session);
          }
        });
      } else {
        console.log("No user");
        reject();
      }
    });
  };

  const handleLogin = async (username, password) => {
    const authDetails = new AuthenticationDetails({
      Username: username,
      Password: password,
    });

    const cognitoUser = new CognitoUser({
      Username: username,
      Pool: UserPool,
    });

    cognitoUser.authenticateUser(authDetails, {
      onSuccess: (data) => {
        console.log("OnSuccess: ", data);
        props.setIsLoggedIn(true);
      },
      onFailure: (data) => {
        console.error("onFailure: ", data);
        alert("Invalid Details! Your username/password is incorrect");
      },
      newPasswordRequired: (userAttrParam) => {
        props.setIsFirstLogin(true);
        setUser(cognitoUser);
        delete userAttrParam.email_verified; //these fields aren't accepted by the API call, hence deleting them
        delete userAttrParam.email;
        setUserAttr(userAttrParam);
      },
    });
  };

  const changePassword = (newPassword) => {
    const cognitoUser = user;
    const userAttributes = userAttr;
    cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes, {
      onSuccess() {
        console.log("Completed the new password challenge!");
        props.setIsFirstLogin(false);
      },
      onFailure(err) {
        console.error("Failed to complete the new password challenge");
        alert("The password you have entered does not fulfil the criteria. Please try again.")
        console.log(err);
      },
    });
  };

    //since this triggers on page reload it keeps the user logged in if there session is still valid (i.e. it hasn't expired in accordance cognito settings.)
    useEffect(() => {
        getSession();
      }, []);

  //return (
  //<LoginContext.Provider>
  //{isFirstLogin ? (
  // <NewPasswordForm changePassword={changePassword} />
  // ) : (
  //    !isLoggedIn(<LoginForm handleLogin={handleLogin} />)
  //  )}
  // </LoginContext.Provider>
  // );

  return (
    <LoginContext.Provider value={{ handleLogin, changePassword, getSession }}>
      {props.children}
    </LoginContext.Provider>
  );
};

export { Login, LoginContext };
import React, { useState } from "react";
import "./App.css";
import Incidents from "./components/Incidents";
import LoginForm from "./components/LoginForm";
import NewPasswordForm from "./components/NewPasswordForm";
import { Login } from "./components/LogIn.js";

function App() {
  const [isFirstLogin, setIsFirstLogin] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  return (
    //this does a conditional check to make sure user is logged in and if they have logged in before.
    <Login setIsFirstLogin={setIsFirstLogin} setIsLoggedIn={setIsLoggedIn}>
      {isLoggedIn ? (
        <Incidents />
      ) : !isFirstLogin ? (
        <LoginForm />
      ) : (
        <NewPasswordForm />
      )}
    </Login>
  );
}

export default App;
import React from "react";
import NewTicket from "./NewTicket";
import NewUpdate from "./NewUpdate";
import CloseTicket from "./CloseTicket";
import Search from "./Search";


const Modal = ({ canShow, updateModalState, setTriggerFetch, btnId, selectedTicketData, ticketUpdatesData, setSearchFlagIsTrue, setSearchParameters,setTicketUpdatesData, setSelectedTicket}) => {
  if (canShow && btnId === "NewTicket") {
    return (
      <div class="py-12 bg-gray-700 bg-opacity-100 transition duration-150 ease-in-out z-10 absolute top-0 right-0 bottom-0 left-0">
        <NewTicket updateModalState={updateModalState} setTriggerFetch={setTriggerFetch}/>
      </div>
    );
  }

  if (canShow && btnId === "NewUpdate") {
    return (
      <div class="py-12 bg-gray-700 bg-opacity-100 transition duration-150 ease-in-out z-10 absolute top-0 right-0 bottom-0 left-0">
        <NewUpdate updateModalState={updateModalState} setTriggerFetch={setTriggerFetch} ticketUpdatesData={ticketUpdatesData} selectedTicketData={selectedTicketData}/>
      </div>
    );
  }

  if (canShow && btnId === "CloseTicket") {
    return (
      <div class="py-12 bg-gray-700 bg-opacity-100 transition duration-150 ease-in-out z-10 absolute top-0 right-0 bottom-0 left-0">
        <CloseTicket updateModalState={updateModalState} setTriggerFetch={setTriggerFetch} selectedTicketData={selectedTicketData} ticketUpdatesData={ticketUpdatesData}/>
      </div>
    );
  }

  if (canShow && btnId === "Search") {
    return (
      <div class="py-12 bg-gray-700 bg-opacity-100 transition duration-150 ease-in-out z-10 absolute top-0 right-0 bottom-0 left-0">
        <Search updateModalState={updateModalState} setTriggerFetch={setTriggerFetch} setSearchFlagIsTrue={setSearchFlagIsTrue} setSearchParameters={setSearchParameters} setTicketUpdatesData={setTicketUpdatesData} setSelectedTicket={setSelectedTicket}/>
      </div>
    );
  }

  return null;
};

export default Modal;
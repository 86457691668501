import React, { useState,useEffect, useContext } from "react";
import axios from "axios";
import { LoginContext } from "./LogIn.js";

//CloseTicket is a bit ambigious naming - its effectively a component intended to change its status between open and closed.
const CloseTicket = ({updateModalState, setTriggerFetch, selectedTicketData, ticketUpdatesData}) => {
    const [loading, setLoading] = useState(true);
    const [closureCategoryData, setClosureCategoryData] = useState([]);
    const [rootCauseData, setRootCauseData] = useState([]);

    const [statusChangeErrorsResp, setStatusChangeErrorsResp] = useState([]);

    const [timeClosedSelection, setTimeClosedSelection] = useState(selectedTicketData[0].TimeOfResolution != undefined ? selectedTicketData[0].TimeOfResolution : ''); //if it is already closed it sets to previously taken value, satisfies api logic req.
    const [closureCategorySelection,setClosureCategorySelection] = useState(selectedTicketData[0].ClosureCategoryID != null ? selectedTicketData[0].ClosureCategoryID : ''); // we leave this null if not being sent back to initiator
    const [rootCauseSelection,setRootCauseSelection] = useState(selectedTicketData[0].RootCauseID != null ? selectedTicketData[0].RootCauseID : '');
    
    const { getSession } = useContext(LoginContext);

    const postStatusChange = async (ticketId, data) => {
        try{
            console.log(data);
            const url = `https://api.jlr-ops-incident-tracker.com/api/tickets/${ticketId}/status`;
            const currentSession = await getSession(); //check to see if still logged in
            const accessToken = currentSession['accessToken']['jwtToken'] //pull token
            const resp = await axios.post(url, data,{headers:{'Authorization':accessToken}});
            console.log(resp.data);
            setStatusChangeErrorsResp([]);
            alert(JSON.stringify(resp.data[0]));
        } catch (err) {
            setStatusChangeErrorsResp(err.response.data.errors);
            alert('Failed to change ticket status. See information below.');
            return (err.response.data.errors)

        }

    }

    const fetchUtilityDataCall = async (endpoint) => {
        const url = `https://api.jlr-ops-incident-tracker.com/api/utilities/${endpoint}`;
        const currentSession = await getSession(); //check to see if still logged in
        const accessToken = currentSession['accessToken']['jwtToken'] //pull token

        const response = await axios.get(url,{headers:{'Authorization':accessToken}});
        console.log("utility Data: ", response.data[0]);
        setLoading(false);
        return await response.data[0];
    };

    const fetchClosureCategory = async () => {
        const closureCateogoryDataResp = await fetchUtilityDataCall(`closurecategories/`);
        console.log(closureCateogoryDataResp);
        setClosureCategoryData(closureCateogoryDataResp);
    }

    const fetchRootCause = async () => {
        const rootCauseDataResp = await fetchUtilityDataCall('rootcauses');
        setRootCauseData(rootCauseDataResp);
    }

    
    const verifyDatetimeString = async (input) => {
        if (input === ''){
            return ""
        } else {
            const dateTimeString = new Date(timeClosedSelection).toISOString().replace("T", " ").replace(".000Z","");
            return dateTimeString;
        }

    }

    const onSubmitHandler = async (e) => {

        e.preventDefault(); //stops it from auto refreshing on form submission - i guess we could leave it as default but it feels less seamless.
        const statusData = {
            TimeOfResolution: `${await verifyDatetimeString(timeClosedSelection)}`,
            ClosureCategoryID: `${closureCategorySelection}`,
            RootCauseID: `${rootCauseSelection}`,
        };

        const resp = await postStatusChange(ticketUpdatesData.slice(-1)[0]['TicketsID'].toString(),statusData);
        if (resp === undefined || resp.length === 0) {
            setTriggerFetch(true);
            updateModalState();
        }
    };

    useEffect(
        () => {
            fetchClosureCategory();
            fetchRootCause();
        },[]);
    
    useEffect(
        () => {
            console.log("error resp useeffect: " + JSON.stringify(statusChangeErrorsResp));
        },[statusChangeErrorsResp]);

    return (
        <div class="items-center justify-center p-12">
            {selectedTicketData[0].Status != "Closed" ? <div>
            <form class="px-4 max-w-3xl mx-auto space-y-4" onSubmit={onSubmitHandler}>
                <div>
                <h1 className="text-3xl font-semibold leading-6 text-white">Closing Ticket:</h1>
                <p class="text-white italic font-thin py-4 font-semibold">"{selectedTicketData[0].IncidentIdentifier} : {selectedTicketData[0].VIN}"</p>
                <p class="text-white font-semibold">Last Update logged at: {new Date(ticketUpdatesData.slice(-1)[0]['TimeOfUpdate']).toString()}</p>
                <p className="mt-1 text-sm text-white">Complete the form to change ticket status.</p>
                </div>
                <div class="">
                    <div>
                        <label htmlFor="ToClos" className="block text-sm font-medium text-white">
                            Time Of Closure*
                        </label>
                        <input type="datetime-local" name="TimeOfClosure" id="TimeOfClosure" value={timeClosedSelection} onChange={(e) => {setTimeClosedSelection(e.target.value); console.log(e.target.value)}} className="my-2 focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md"/>    
                    </div>
                    <div class="">
                        <label htmlFor="ClosureCategory*" className="block text-sm font-medium text-white">Closure Category*</label>
                        <select disabled={loading} name="ClosureCategoryID" id="ClosureCategoryID" value={closureCategorySelection} onChange={(e) => {setClosureCategorySelection(e.target.value); console.log(e.target.value)}} className="my-2 focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md">
                            <option></option>
                            {closureCategoryData.map(item => (<option key={item.ClosureCategoryID} value={item.ClosureCategoryID}>{item.ClosureCategoryName}</option>))}
                        </select>
                    </div>
                    <div class=""> 
                        <div class="">
                            <label htmlFor="RootCause" className="block text-sm font-medium text-white">Root Cause*</label>
                            <select disabled={loading} name="RootCause" id="RootCauseID" value={rootCauseSelection} onChange={(e) => {setRootCauseSelection(e.target.value); console.log(e.target.value)}} className="my-2 focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md">
                                <option></option>
                                {rootCauseData.map(item => (<option key={item.RootCauseID} value={item.RootCauseID}>{item.Name}</option>))}     
                            </select>
                        </div>
                    </div>
                </div>
                <button type="submit" class="p-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Submit</button>
                <button class="p-2 mx-8 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" onClick={updateModalState}>Cancel</button>
            </form>
            {statusChangeErrorsResp.length != 0 && <div class="text-red-500">{JSON.stringify(statusChangeErrorsResp)}</div>}
        </div>
        : 
        <div>
            <form class="px-4 max-w-3xl mx-auto space-y-4" onSubmit={onSubmitHandler}>
                <div>
                <h1 className="text-3xl font-semibold leading-6 text-white">Re-opening Ticket:</h1>
                <p class="text-white italic font-thin py-4 font-semibold">"{selectedTicketData[0].IncidentIdentifier} : {selectedTicketData[0].VIN}"</p>
                <p class="text-white font-semibold">Last Update logged at: {new Date(ticketUpdatesData.slice(-1)[0]['TimeOfUpdate']).toString()}</p>
                <p className="mt-1 text-sm text-white">To continue adding updates to this ticket, press the "Re-open button below".</p>
                <div class="space-y-8">
                    <button type="submit" class="p-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Re-open Ticket</button>
                    <button class="p-2 mx-8 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" onClick={updateModalState}>Cancel</button>
                </div>
                </div>              
            </form>
        </div>}
        </div>
        )};

export default CloseTicket;
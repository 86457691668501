import {useState, useEffect, useContext} from 'react'
import axios from 'axios'
import Table from './Table'
import Updates from './Updates';
import Modal from './Modal';
import { LoginContext } from "./LogIn.js";


const Incidents = () => {
    

    const [ticketsData, setTicketsData] = useState([]);
    const [selectedTicket, setSelectedTicket] = useState([]);
    const [selectedTicketData, setSelectedTicketData] = useState([]);
    const [ticketUpdatesData, setTicketUpdatesData] = useState([]);
    const [showModal, updateShowModal] = useState(false);
    const [triggerFetch, setTriggerFetch] = useState(false);
    const [btnId, setBtnId] = useState([]);

    const [loading, setLoading] = useState(true);



    //states to control search capabilities. If searchFlag is FALSE, then use the user default view parameters (i.e. TSD or Third line, or whatever is chosen as assignee in dropdown)
    //if searchFlag is TRUE, hide the dropdown capability until searchfilter is reset.
    const [searchFlagIsTrue, setSearchFlagIsTrue] = useState(false);
    const [searchParameters, setSearchParameters] = useState([]);

    const [workingGroupsData, setWorkingGroupsData] = useState([]);
    const [defaultViewParameterSelection, setDefaultViewParameterSelection] = useState(6)
    const [teamID, setTeamID] = useState('');
    const [userName,setUserName] = useState('');
    const [role, setRole] = useState('');

    const { getSession } = useContext(LoginContext);


    const toggleModal = () => updateShowModal(state => !state);

    const URL = `https://api.jlr-ops-incident-tracker.com/api/`;

    const onTicketSelectHandler = (row) => {
        //console.log(JSON.stringify(row.values.TicketsID));
        setSelectedTicket(row.values.TicketsID);

        setSelectedTicketData(ticketsData.filter(obj => {

            return obj.TicketsID === row.values.TicketsID;
          }));
    };

    const onButtonClickHandler = (event) => {
        //console.log(event.currentTarget.id);
        setBtnId(event.currentTarget.id);
        toggleModal();
        
    };



    const fetchTickets = async (url) => { //work done to query the TT API
        const currentSession = await getSession(); //check to see if still logged in
        const accessToken = currentSession['accessToken']['jwtToken'] //pull token
        const usernameVar = currentSession['idToken']['payload']['username'];
        const teamIdVar = currentSession['idToken']['payload']['custom:team']
        const roleVar = currentSession['idToken']['payload']['custom:role'];
        setUserName(usernameVar);
        setTeamID(teamIdVar === "Third Line" ? 5 : 6);
        setRole(roleVar);
        

        const response = await axios.get(url, {headers:{'Authorization':accessToken}});
        console.log("tickets: ", response.data[0]);
        setTicketsData(response.data[0]); //setting api data object to the ticketsData state. This state update will trigger a DOM render
        };

    const fetchUpdates = async (url) => { //work done to query the TT API
        const currentSession = await getSession(); //check to see if still logged in
        const accessToken = currentSession['accessToken']['jwtToken'] //pull token

        const response = await axios.get(url, {headers:{'Authorization':accessToken}});
        console.log("ticket Update: ", response.data[0]);
        setTicketUpdatesData(response.data[0]); //setting api data object to the ticketsData state. This state update will trigger a DOM render
        };

    const deleteTicket = async (url) => {
        const confirmedDeletion = window.confirm(`Are you sure you want to delete: ${selectedTicketData[0].IncidentIdentifier} : ${selectedTicketData[0].VIN}`);

        if (confirmedDeletion) {
            const currentSession = await getSession(); //check to see if still logged in
            const accessToken = await currentSession['accessToken']['jwtToken'] //pull token
            axios.delete(url, {headers:{'Authorization':accessToken}});
            setTriggerFetch(true);
            setSelectedTicket([]);
            setSelectedTicketData([]);
            alert("Ticket Deleted!")
        };
    };
// maybe could join these two functions as they are similar, but separation of concerns etc etc.
    const deleteLatestUpdate = async (url) => {
        
        const confirmedDeletion = window.confirm(`Are you sure you want to delete the latest update for: ${selectedTicketData[0].IncidentIdentifier} : ${selectedTicketData[0].VIN}`);
        if (confirmedDeletion) {
            const currentSession = await getSession(); //check to see if still logged in
            const accessToken = currentSession['accessToken']['jwtToken'] //pull token
            axios.delete(url, {headers:{'Authorization':accessToken}});
            setTriggerFetch(true);
            alert("Update Deleted!")
        };
    };

    const fetchUtilityDataCall = async (endpoint) => {
        const url = `https://api.jlr-ops-incident-tracker.com/api/utilities/${endpoint}`;
        const currentSession = await getSession(); //check to see if still logged in
        const accessToken = currentSession['accessToken']['jwtToken'] //pull token
        console.log(accessToken);
        const response = await axios.get(url, {headers:{'Authorization':accessToken}});
        console.log("utility Data: ", response.data[0]);
        setLoading(false);
        return await response.data[0];
    };


    const emergingIssueFlagUpdate = async (ticketId) => {
        const url = `https://api.jlr-ops-incident-tracker.com/api/tickets/${ticketId}/issueFlagToggle`;
        const confirmedFlagUpdate = window.confirm(`Are you sure you want to update the Emerging Issue Flag for: ${selectedTicketData[0].IncidentIdentifier} : ${selectedTicketData[0].VIN}`);
        if (confirmedFlagUpdate) {
            const currentSession = await getSession(); //check to see if still logged in
            const accessToken = currentSession['accessToken']['jwtToken']; //pull token
            axios.post(url, {}, {headers:{'Authorization':accessToken}});
            setTriggerFetch(true);
            alert("Flag Updated!");
        };
    };

    const fetchWorkingGroups = async () => {
        const WorkingGroupsDataResp = await fetchUtilityDataCall('workinggroups');
        setWorkingGroupsData(WorkingGroupsDataResp);
    }

    useEffect(() => {
        if (searchFlagIsTrue){
            let searchParamString = JSON.stringify(searchParameters).replace(/{|}/g, "").replace(/:/g, "=").replace(/,/g,"&").replace(/"/g,"");
            console.log(searchParamString);
            fetchTickets(URL+`tickets`+`?${searchParamString}`); //pulls available tickets upon DOM render
        }else{
            fetchTickets(URL+`tickets`+`?assignee=${defaultViewParameterSelection}&status=1`);
        };

        if(selectedTicket.length !== 0) {
            fetchUpdates(URL+`tickets/${selectedTicket}/updates`);
        };

        fetchWorkingGroups();

        console.log("confirming useEffect has been ran1");
        setTriggerFetch(false);
        const interval=setInterval( //this sets an interval between ticket data refresh, milliseconds.
            ()=>{if (searchFlagIsTrue){
                let searchParamString = JSON.stringify(searchParameters).replace(/{|}/g, "").replace(/:/g, "=").replace(/,/g,"&").replace(/"/g,"");
                console.log(searchParamString);
                fetchTickets(URL+`tickets`+`?${searchParamString}`); //pulls available tickets upon DOM render
            }else{
                fetchTickets(URL+`tickets`+`?assignee=${defaultViewParameterSelection}&status=1`);};

            if(selectedTicket.length !== 0) {
                console.log("ticket selected for interval update: ", selectedTicket)
                fetchUpdates(URL+`tickets/${selectedTicket}/updates`);};},60000)
                return()=>clearInterval(interval);
    },[,triggerFetch]); //fetches on initial render and then when triggerFetch state switches.

    useEffect(() => {
        if(selectedTicket.length !== 0) { //check if array shows a selected ticket, if so run updates query.
            console.log("New ticket has been selected " + selectedTicket);
            fetchUpdates(URL+`tickets/${selectedTicket}/updates`); //trigger stateUpdate
            console.log("confirming TicketUpdate useEffect has been ran.");
        }

    },[selectedTicket]);

    return (<div class="flex justify-center">
        <div class="w-screen h-screen">
            <div class="h-3/5 overflow-hidden">
                <Table data={ticketsData} onTicketSelectHandler={onTicketSelectHandler} selectedTicket={selectedTicket}/>
            </div>
            <div class="h-2/5 overflow-hidden">
                <Updates data={ticketUpdatesData}/>
            </div>
            </div>
            <div class="absolute bottom-0 bg-gray-600 w-full py-4">
            <button id="NewTicket" type="button" class="py-2 px-2 mx-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" onClick={onButtonClickHandler}>New Ticket</button>
            <button id="NewUpdate" type="button" class="py-2 px-2 mx-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" onClick={(event) => (selectedTicket.length !== 0 && selectedTicketData[0].Status == "Open") ? onButtonClickHandler(event) : alert("Ticket must be selected and OPEN to make an update.")}>New Update</button>
            <button id="CloseTicket" type="button" class="py-2 px-2 mx-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" onClick={(event) => (selectedTicket.length !== 0 && selectedTicketData[0].AssigneeGroup === selectedTicketData[0].InitiatorGroup ) ? onButtonClickHandler(event) : alert("Ticket must be selected to change its status OR be back with Initiator Group.")}>{(selectedTicket.length !== 0 && selectedTicketData[0].Status == "Open") ? "Close Ticket" : "Reopen Ticket"}</button>
            <button id="Search" type="button" class="py-2 px-2 mx-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" onClick={onButtonClickHandler}>Search</button>
            <button id="EmergingIssueFlag" type="button" class="py-2 px-2 mx-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" onClick={() => (selectedTicket.length !== 0) ? emergingIssueFlagUpdate(selectedTicket) : alert("Ticket must be selected to set/remove Emerging Issue Flag")}>{(selectedTicket.length !== 0 && selectedTicketData[0].EmergingIssueFlag == 0) ? "Flag Emerging Issue" : "Remove Issue Flag"}</button>
            {searchFlagIsTrue == true && <button id="ResetSearch" type="button" class="py-2 px-2 mx-2 text-sm font-medium text-white focus:outline-none bg-red-600 rounded-lg border border-red-600 hover:bg-red-700 hover:text-white focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" onClick={() => {setTicketUpdatesData([]); setSelectedTicket([]); setSearchFlagIsTrue(false); setTriggerFetch(true);}}>Reset Search</button>}
            <div class="flex absolute bottom-0 right-0">
                {searchFlagIsTrue != true && <label class="text-white font-bold">Group View ---></label>}
                {searchFlagIsTrue != true && <select name="WorkingGroupsID" id="GroupID" value={defaultViewParameterSelection} onChange={(e) => {setTicketUpdatesData([]);setSelectedTicket([]);setDefaultViewParameterSelection(e.target.value); setTriggerFetch(true); console.log(e.target.value)}} className="my-2 focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md">
                {workingGroupsData.map(item => (<option key={item.GroupsID} value={item.GroupsID}>{item.GroupName}</option>))}</select>}
                <div class="grow-0">
                    {selectedTicket.length !== 0 && <button id="DeleteTicket" type="button" class="py-2 px-2 mx-2 float-right align text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" onClick={() => deleteTicket(URL+`tickets/${selectedTicket}`)}>Delete Ticket</button>}
                    {selectedTicket.length !== 0 && <button id="DeleteUpdate" type="button" class="py-2 px-2 mx-2 float-right text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" onClick={() => (ticketUpdatesData.length > 1 && selectedTicketData[0].Status == "Open") ? deleteLatestUpdate(URL+`tickets/${selectedTicket}/updates`) : alert("There is one or less updates logged for this ticket. Please use the 'DELETE TICKET' function if it must be removed")}>Delete Latest Update</button>}
                </div>
            </div>
            </div>
            <Modal canShow={showModal} updateModalState={toggleModal} setTriggerFetch={setTriggerFetch} btnId={btnId} ticketUpdatesData={ticketUpdatesData} selectedTicketData={selectedTicketData} setSearchFlagIsTrue={setSearchFlagIsTrue} setSearchParameters={setSearchParameters} setTicketUpdatesData={setTicketUpdatesData} setSelectedTicket={setSelectedTicket}></Modal>
            </div>)

    }
    export default Incidents;
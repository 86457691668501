import React, { useState,useEffect, useContext } from "react";
import axios from "axios";
import { LoginContext } from "./LogIn.js";

const NewTicket = ({updateModalState, setTriggerFetch}) => {

    const [loading, setLoading] = useState(true);
    const [ticketTypesData, setTicketTypesData] = useState([]);
    const [initiatorGroupData, setInitiatorGroupData] = useState([]);
    const [countryData, setCountryData] = useState([]);
    const [categoryData, setCategoryData] = useState([]);
    const [prioritiesData, setPrioritiesData] = useState([]);
    const [rootCauseData, setRootCausesData] = useState([]);
    const [createErrorsResp, setCreateErrorsResp] = useState([]);

    const [ticketTypeSelection, setTicketTypeSelection] = useState(''); //need to add this to the api...
    const [incidentNameSelection, setIncidentNameSelection] = useState('');
    const [vin,setVin] = useState('');
    const [timeArrivedSelection, setTimeArrivedSelection] = useState('');
    const [timeAcknowledgedSelection, setTimeAcknowledgedSelection] = useState('');
    const [initiatorGroupSelection, setInitiatorGroupSelection] = useState('');
    const [countrySelection,setCountrySelection] = useState('');
    const [categorySelection,setCategorySelection] = useState('');
    const [prioritySelection, setPrioritySelection] = useState('');
    const [rootCauseSelection,setRootCauseSelection] = useState('');
    const [descriptionSelection, setDescriptionSelection] = useState('');

    const { getSession } = useContext(LoginContext);

    const postNewTicket = async (data) => {
        try{
            console.log("post data: " , data);
            const url = `https://api.jlr-ops-incident-tracker.com/api/tickets/`;
            const currentSession = await getSession(); //check to see if still logged in
            const accessToken = currentSession['accessToken']['jwtToken'] //pull token
            const resp = await axios.post(url, data,{headers:{'Authorization':accessToken}});
            console.log(resp.data);
            setCreateErrorsResp([]);
            alert('Ticket created.');
        } catch (err) {
            setCreateErrorsResp(err.response.data.errors);
            alert('Failed to Create the ticket. See information below.');
            return (err.response.data.errors)

        }

    }

    const fetchUtilityDataCall = async (endpoint) => {
        const url = `https://api.jlr-ops-incident-tracker.com/api/utilities/${endpoint}`;
        const currentSession = await getSession(); //check to see if still logged in
        const accessToken = currentSession['accessToken']['jwtToken'] //pull token
        const response = await axios.get(url, {headers:{'Authorization':accessToken}});
        console.log("utility Data: ", response.data[0]);
        setLoading(false);
        return await response.data[0];
    };

    const fetchTicketType = async () => {
        const ticketTypeDataResp = await fetchUtilityDataCall('tickettypes');
        setTicketTypesData(ticketTypeDataResp);
    }

    const fetchInitiatorGroup = async () => {
        const initiatorGroupDataResp = await fetchUtilityDataCall('currentdispatcher/new');
        setInitiatorGroupData(initiatorGroupDataResp);
    }

    const fetchCountries = async () => {
        const countryDataResp = await fetchUtilityDataCall('countries');
        setCountryData(countryDataResp);
    }

    const fetchCategories = async () => {
        const categoryDataResp = await fetchUtilityDataCall('ticketcategories');
        setCategoryData(categoryDataResp);
    }

    const fetchPriorities = async () => {
        const prioritiesDataResp = await fetchUtilityDataCall('priorities');
        setPrioritiesData(prioritiesDataResp);
    }

    const verifyDatetimeString = async (input) => {
        if (input === ''){
            return ""
        } else {
            const dateTimeString = new Date(input).toISOString().replace("T", " ").replace(".000Z","");
            return dateTimeString;
        }

    }

    const getLoggedInUsername = async () => {
        const currentSession = await getSession();
        const username = await currentSession['idToken']['payload']['cognito:username'];
        return username;
    };

    const onSubmitHandler = async (e) => {

        e.preventDefault(); //stops it from auto refreshing on form submission - i guess we could leave it as default but it feels less seamless.
        const ticketData = {
            InitiatorGroupID: `${initiatorGroupSelection}`,
            AssigneeID : `6`, // TSD ID
            IncidentIdentifier: `${incidentNameSelection}`,
            Description: `${descriptionSelection}`,
            VIN: `${vin}`,
            TimeOfArrival: `${await verifyDatetimeString(timeArrivedSelection)}`,
            TimeOfAcknowledgement: `${await verifyDatetimeString(timeAcknowledgedSelection)}`,
            CountryID: `${countrySelection}`,
            CategoryID: `${categorySelection}`,
            PriorityID: `${prioritySelection}`,
            StatusID: `1`, // 1 id is Open
            TicketTypeID: `${ticketTypeSelection}`,
            HandledByUserID: `${await getLoggedInUsername()}`,
        }

        const resp = await postNewTicket(ticketData);
        if (resp === undefined || resp.length === 0) {
            setTriggerFetch(true);
            updateModalState();
        }
    };

    useEffect(
        () => {
            fetchTicketType();
            fetchInitiatorGroup();
            fetchCountries();
            fetchCategories();
            fetchPriorities();

        },[])

    useEffect(
        () => {
            console.log("error resp useeffect: " + JSON.stringify(createErrorsResp));
        },[createErrorsResp]
    );

      return (
        <div class="items-center justify-center p-12">
          <div class="">
            <form class="px-4 max-w-3xl mx-auto space-y-4" onSubmit={onSubmitHandler}>
                <div>
                <h1 className="text-3xl font-semibold leading-6 text-white">Create Ticket</h1>
                <p className="mt-1 text-sm text-white">Complete the form to add a ticket.</p>
                </div>
                <div class="flex space-x-4">
                <div class="">
                    <label htmlFor="Type" className="block text-sm font-medium text-white">
                    Type*
                    </label>
                    <select disabled={loading} name="TicketTypeID" id="TicketTypeID" value={ticketTypeSelection} onChange={(e) => {setTicketTypeSelection(e.target.value); console.log(e.target.value)}} className="my-2 focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md">
                    <option></option>
                    {ticketTypesData.map(item => (<option key={item.TicketTypeID} value={item.TicketTypeID}>{item.TicketFrom}</option>))}
                    </select>
                    </div>
                    <div>
                    <label htmlFor="IncidentIdentifier" className="block text-sm font-medium text-white">
                    Incident Identifier*
                    </label>
                    <input type="text" name="TA/CCR Reference" id="IncidentIdentifier" value={incidentNameSelection} onChange={(e) => {setIncidentNameSelection(e.target.value); console.log(e.target.value)}} className="my-2 focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md"/>
                    </div>
                    <div>
                        <label htmlFor="VIN" className="block text-sm font-medium text-white">
                            VIN (17 Characters)*
                            </label>
                            <input type="text" name="VIN" id="VIN" value={vin} onChange={(e) => {setVin(e.target.value); console.log(e.target.value)}} className="my-2 focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md"/>
                    </div>
                </div>
                    <div class="flex space-x-4">
                    <div>
                        <label htmlFor="ToArr" className="block text-sm font-medium text-white">
                            Time Of Arrival*
                        </label>
                        <input type="datetime-local" name="Time Of Arrival" id="TimeofArrival" value={timeArrivedSelection} onChange={(e) => {setTimeArrivedSelection(e.target.value); console.log(e.target.value)}} className="my-2 focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md"/>    
                    </div>
                    <div>
                        <label htmlFor="ToAck" className="block text-sm font-medium text-white">
                            Time Of Acknowledgement*
                        </label>
                        <input type="datetime-local" name="Time Of Acknowledgement" id="TimeOfAcknowledgement" value={timeAcknowledgedSelection} onChange={(e) => {setTimeAcknowledgedSelection(e.target.value); console.log(e.target.value)}} className="my-2 focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md"/>    
                    </div>
                    </div>
                    <div class="flex space-x-4">
                        <div class="">
                            <label htmlFor="InitiatorGroupID" className="block text-sm font-medium text-white">Source*</label>
                            <select disabled={loading} name="InitiatorGroupID" id="InitiatorGroupID" value={initiatorGroupSelection} onChange={(e) => {setInitiatorGroupSelection(e.target.value); console.log(e.target.value)}} className="my-2 focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md">
                                <option></option>
                                {initiatorGroupData.map(item => (<option key={item.GroupsID} value={item.GroupsID}>{item.GroupName}</option>))}
                            </select>
                        </div>
                        <div class="">
                            <label htmlFor="Country" className="block text-sm font-medium text-white">Country*</label>
                            <select disabled={loading} name="Country" id="CountryID" value={countrySelection} onChange={(e) => {setCountrySelection(e.target.value); console.log(e.target.value)}} className="my-2 focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md">
                                <option></option>
                                {countryData.map(item => (<option key={item.CountryID} value={item.CountryID}>{item.CountryName}</option>))}
                            </select>
                        </div>
                    </div>
                    <div class="flex space-x-4">
                        <div class="">
                            <label htmlFor="Category" className="block text-sm font-medium text-white">Service*</label>
                            <select disabled={loading} name="CategoryID" id="CategoryID" value={categorySelection} onChange={(e) => {setCategorySelection(e.target.value); console.log(e.target.value)}}  className="my-2 focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md">
                                <option></option>
                                {categoryData.map(item => (<option key={item.CategoryID} value={item.CategoryID}>{item.CategoryName}</option>))}
                            </select>
                        </div>
                        <div class="">
                            <label htmlFor="Priority" className="block text-sm font-medium text-white">Priority*</label>
                            <select disabled={loading} name="Priority" id="PriorityID" value={prioritySelection} onChange={(e) => {setPrioritySelection(e.target.value); console.log(e.target.value)}} className="my-2 focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md">
                                <option></option>
                                {prioritiesData.map(item => (<option key={item.PriorityID} value={item.PriorityID}>{item.PriorityName}</option>))}
                            </select>
                        </div>
                    </div>
                    <div>
                        <label htmlFor="Description" className="block text-sm font-medium text-white">
                            Description
                            </label>
                            <textarea id="descriptionArea" name="description" onChange={(e) => {setDescriptionSelection(e.target.value); console.log(e.target.value)}} rows="3" cols="55" className="my-2 focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md resize-none"/>
                    </div>
                    <button type="submit" class="p-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Submit</button>
                    <button class="p-2 mx-8 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" onClick={updateModalState}>Cancel</button>
                    </form>
                    {createErrorsResp.length != 0 &&<div class="text-red-500">{JSON.stringify(createErrorsResp)}</div>}
                    </div>
        </div>
      );
  
  };
  
  export default NewTicket;
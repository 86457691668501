import { useMemo } from "react"
import { useTable } from "react-table"
const Updates = (props) => {

  const dateFormatter = (datestring) => {
    if (datestring === null) {
      return ""
    } else {

      const date = new Date(datestring);
      const dateString = date.toISOString().split('T')[0];
      const timeString = date.toISOString().split('T')[1].split('.')[0];
      return (dateString + " " + timeString);

    }
  }


    const data = useMemo(
        () => props.data,
        [props.data]);

    const columns = useMemo(
        () => [{
            Header: 'Ticket Update ID',
            accessor: 'TicketUpdatesID', // accessor is the "key" in the data
          },
          {
            Header: 'Dispatch Group',
            accessor: 'DispatchGroup',
          },
          {
            Header: 'Destination Group',
            accessor: 'DestinationGroup',
          },
          {
            Header: 'Update Type',
            accessor: 'UpdateTypeName',
          },
          {
            Header: 'Updated by',
            accessor: 'LoggedByUser',
          },
          {
            Header: 'Time',
            accessor: d => {return dateFormatter(d.TimeOfUpdate)},
          },
          {
            Header: 'DispatchNotes',
            accessor: 'DispatchNotes',
          },
          {
            Header: 'ElapsedTime',
            accessor: 'ElapsedTime',
          },],
        []
      )

      const tableInstance = useTable({ columns, data,});
      const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
      } = tableInstance;

      return (
        // apply the table props
        <table class="text-left w-screen h-screen" {...getTableProps()}>
          <thead class="bg-gray-600 flex text-white w-screen">
            {// Loop over the header rows
            headerGroups.map(headerGroup => (
              // Apply the header row props
              <tr class="flex w-screen" {...headerGroup.getHeaderGroupProps()}>
                {// Loop over the headers in each row
                headerGroup.headers.map(column => (
                  // Apply the header cell props
                  <th class="p-4 w-1/4" {...column.getHeaderProps()}>
                    {// Render the header
                    column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {/* Apply the table body props */}
          <tbody class="bg-grey-light flex flex-col items-center overflow-auto w-screen h-1/4" {...getTableBodyProps()}>
            {// Loop over the table rows
            rows.map(row => {
              // Prepare the row for display
              prepareRow(row)
              return (
                // Apply the row props
                //Also pulling the clicked row value using OnClick event. TODO: Need to write a handler to save the state for the relevant info.
                <tr class="flex w-full hover:bg-gray-100 border-2" {...row.getRowProps()}>
                  {// Loop over the rows cells
                  row.cells.map(cell => {
                    // Apply the cell props
                    return (
                      <td class="px-4 w-1/4" {...cell.getCellProps()}>
                        {// Render the cell contents
                        cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      )

      //return (<div>{JSON.stringify(props.data)}</div>);
};


export default Updates;
import { useState, useContext } from "react";
import { LoginContext } from "./LogIn.js";

const NewPasswordForm = () => {
  const [changedPassword, setChangedPassword] = useState("");

  const { changePassword } = useContext(LoginContext);

  const onSubmitHandler = (e) => {
    e.preventDefault();
    changePassword(changedPassword);
  };

  return (
    <div class="relative flex flex-col justify-center min-h-screen overflow-hidden">
      <div class="w-full p-6 m-auto bg-white border-t border-purple-600 rounded shadow-lg shadow-purple-800/50 lg:max-w-md">
        <h1 class="text-3xl font-semibold text-center text-purple-700">
          OPS INCIDENT TRACKER
        </h1>
        <form class="mt-6" onSubmit={onSubmitHandler}>
          <div class="mt-4">
            <div>
              <label for="password" class="block text-sm text-gray-800">
                New Password
              </label>
              <input
                type="password"
                class="block w-full px-4 py-2 mt-2 text-purple-700 bg-white border rounded-md focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40"
                value={changedPassword}
                onChange={(e) => {
                  setChangedPassword(e.target.value);
                }}
              />
            </div>
            <p class="text-xs text-gray-600 hover:underline">
              Rules: 12 characters, 1 Uppercase letter, 1 Lowercase letter, a number, a special character (e.g. !, %, @)
            </p>
            <div class="mt-6">
              <button
                type="submit"
                class="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-purple-700 rounded-md hover:bg-purple-600 focus:outline-none focus:bg-purple-600"
              >
                Change Password
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewPasswordForm;

import React, { useState,useEffect,useContext } from "react";
import axios from "axios";
import { LoginContext } from "./LogIn.js";


const Search = (props) => {
    //search parameter options which are pulled from API and held in state variables.
    const [statusData, setStatusData] = useState([{'StatusID': 1, 'StatusName':"Open"},{'StatusID': 2, 'StatusName':"Closed"}]);
    const [workingGroupData, setWorkingGroupData] = useState([]);
    const [prioritiesGroupData, setPrioritiesGroupData] = useState([]);
    const [countryGroupData, setCountryGroupData] = useState([]);
    const [categoryGroupData, setCategoryGroupData] = useState([]);
    const [emergingIssueData, setEmergingIssueData] = useState([{'EmergingIssueID': 1,'EmergingIssueFlag': "True"}]);
    const [loading, setLoading] = useState([]);

    //search query parameters in selected and stored in these state variables.
    const [incidentRefSelection, setIncidentRefSelection] = useState('');
    const [vinStringSelection, setVinStringSelection] = useState('');
    const [descriptionSelection, setDescriptionSelection] = useState('');
    const [statusSelection, setStatusSelection] = useState('');
    const [initiatorGroupSelection, setInitiatorGroupSelection] = useState('');
    const [assigneeGroupSelection, setAssigneeGroupSelection] = useState('');
    const [priorityGroupSelection, setPriorityGroupSelection] = useState('');
    const [countryGroupSelection, setCountryGroupSelection] = useState('');
    const [categoryGroupSelection, setCategoryGroupSelection] = useState('');
    const [emergingIssueFlagSelection, setEmergingIssueFlagSelection] = useState('');

    const { getSession } = useContext(LoginContext);

    const fetchUtilityDataCall = async (endpoint) => {
        const url = `https://api.jlr-ops-incident-tracker.com/api/utilities/${endpoint}`;
        const currentSession = await getSession(); //check to see if still logged in
        const accessToken = currentSession['accessToken']['jwtToken'] //pull token

        const response = await axios.get(url,{headers:{'Authorization':accessToken}});
        console.log("utility Data: ", response.data[0]);
        setLoading(false);
        return await response.data[0];
    };

    const fetchWorkingGroups = async () => {
        const workingGroupDataResp = await fetchUtilityDataCall(`workinggroups`);
        console.log(workingGroupDataResp);
        setWorkingGroupData(workingGroupDataResp);
    }

    const fetchCountries = async () => {
        const countryDataResp = await fetchUtilityDataCall('countries');
        setCountryGroupData(countryDataResp);
    }

    const fetchCategories = async () => {
        const categoryDataResp = await fetchUtilityDataCall('ticketcategories');
        setCategoryGroupData(categoryDataResp);
    }

    const fetchPriorities = async () => {
        const prioritiesDataResp = await fetchUtilityDataCall('priorities');
        setPrioritiesGroupData(prioritiesDataResp);
    }

    useEffect(
        () => {
            fetchWorkingGroups();
            fetchPriorities();
            fetchCategories();
            fetchCountries();
        },[]);
    
    const onSubmitHandler = async (e) => {

        e.preventDefault(); //stops it from auto refreshing on form submission - i guess we could leave it as default but it feels less seamless.
        const searchParamData = {
            incref: incidentRefSelection,
            vin : vinStringSelection,
            desc: descriptionSelection,
            status: statusSelection != '' && parseInt(statusSelection),
            initiator: initiatorGroupSelection != '' && parseInt(initiatorGroupSelection),
            assignee: assigneeGroupSelection != '' && parseInt(assigneeGroupSelection),
            prio: priorityGroupSelection != '' && parseInt(priorityGroupSelection),
            category: categoryGroupSelection != '' && parseInt(categoryGroupSelection),
            country: countryGroupSelection != '' && parseInt(countryGroupSelection),
            emergingIssue: emergingIssueFlagSelection != '' && parseInt(emergingIssueFlagSelection), 
        }
        //a funky one-liner which filters out parameters which have been left empty, cleaning up the parameter query string for the API.
        let o = Object.fromEntries(Object.entries(searchParamData).filter(([_, v]) => v != ''));
        //let notNullParams = searchParamData.filter(x => x !== null);
        //console.log(notNullParams);

        if(Object.keys(o).length <  1){
            alert("No query options have been selected. Please input at least one value into the specified fields. If not required, press 'Cancel'.")
        }else{
            props.setSelectedTicket([]);
            props.setTicketUpdatesData([]);
            props.setSearchParameters(o);
            props.setSearchFlagIsTrue(true);
            props.setTriggerFetch(true);
            props.updateModalState();
        }
        };

    return (
        <div class="items-center justify-center p-12">
            <div>
            <form class="px-4 max-w-3xl mx-auto space-y-4" onSubmit={onSubmitHandler}>
                <div>
                <h1 class="text-3xl font-semibold leading-6 text-white">Tracker Querying Filters</h1>
                <div class="py-2"/>
                <p class="text-white font-semibold">Use the various input criteria and drop-downs to search for specific tickets</p>
                <p className="mt-1 text-sm text-white"><b>Submit</b> the content below to view curated tickets... if not required leave selection box <b>blank</b>.</p>
                </div>
                <div class="flex space-x-4">
                    <div class="">
                        <label htmlFor="IncidentIdentifier" className="block text-sm font-medium text-white">Incident Identifier</label>
                        <input type="text" name="Incref" id="IncidentIdentifier" value={incidentRefSelection} onChange={(e) => {setIncidentRefSelection(e.target.value); console.log(e.target.value)}} className="my-2 focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md"/>
                    </div>
                    <div class="">
                        <label htmlFor="Description" className="block text-sm font-medium text-white">Description</label>
                        <input type="text" name="Description" id="Description" value={descriptionSelection} onChange={(e) => {setDescriptionSelection(e.target.value); console.log(e.target.value)}} className="my-2 focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md"/>
                    </div>
                    <div class="">
                        <label htmlFor="VIN" className="block text-sm font-medium text-white">VIN</label>
                        <input type="text" name="VIN" id="VIN" value={vinStringSelection} onChange={(e) => {setVinStringSelection(e.target.value); console.log(e.target.value)}} className="my-2 focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md"/>
                    </div>
                </div>
                <div class="flex space-x-4">
                    <div class="">
                        <label htmlFor="Status" className="block text-sm font-medium text-white">Status</label>
                        <select disabled={loading} name="Status" id="StatusID" value={statusSelection} onChange={(e) => {setStatusSelection(e.target.value); console.log(e.target.value)}} className="my-2 focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md">
                            <option value=''>Choose one</option>
                            {statusData.map(item => (<option key={item.StatusID} value={item.StatusID}>{item.StatusName}</option>))}
                        </select>
                    </div>
                    <div class="">
                        <label htmlFor="Source Group" className="block text-sm font-medium text-white">Source Group</label>
                        <select disabled={loading} name="InitiatorGroup" id="InitiatorGroupID" value={initiatorGroupSelection} onChange={(e) => {setInitiatorGroupSelection(e.target.value); console.log(e.target.value)}} className="my-2 focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md">
                            <option value=''>Choose one</option>
                            {workingGroupData.map(item => (<option key={item.GroupsID} value={item.GroupsID}>{item.GroupName}</option>))}
                        </select>
                    </div>
                    <div class="">
                        <label htmlFor="Current Location" className="block text-sm font-medium text-white">Current Location</label>
                        <select disabled={loading} name="AssigneGroup" id="AssigneeGroupID" value={assigneeGroupSelection} onChange={(e) => {setAssigneeGroupSelection(e.target.value); console.log(e.target.value)}} className="my-2 focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md">
                            <option value=''>Choose one</option>
                            {workingGroupData.map(item => (<option key={item.GroupsID} value={item.GroupsID}>{item.GroupName}</option>))}
                        </select>
                    </div>
                </div>
                <div class="flex space-x-4">
                    <div class="">
                        <label htmlFor="Priority" className="block text-sm font-medium text-white">Priority</label>
                        <select disabled={loading} name="Priority" id="PriorityID" value={priorityGroupSelection} onChange={(e) => {setPriorityGroupSelection(e.target.value); console.log(e.target.value)}} className="my-2 focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md">
                            <option value=''>Choose one</option>
                            {prioritiesGroupData.map(item => (<option key={item.PriorityID} value={item.PriorityID}>{item.PriorityName}</option>))}
                        </select>
                    </div>
                    <div class="">
                        <label htmlFor="Category" className="block text-sm font-medium text-white">Category</label>
                        <select disabled={loading} name="Category" id="CategoryID" value={categoryGroupSelection} onChange={(e) => {setCategoryGroupSelection(e.target.value); console.log(e.target.value)}} className="my-2 focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md">
                            <option value=''>Choose one</option>
                            {categoryGroupData.map(item => (<option key={item.CategoryID} value={item.CategoryID}>{item.CategoryName}</option>))}
                        </select>
                    </div>
                    <div class="">
                        <label htmlFor="CountryISO3" className="block text-sm font-medium text-white">Market</label>
                        <select disabled={loading} name="Country" id="CountryID" value={countryGroupSelection} onChange={(e) => {setCountryGroupSelection(e.target.value); console.log(e.target.value)}} className="my-2 focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md">
                            <option value=''>Choose one</option>
                            {countryGroupData.map(item => (<option key={item.CountryID} value={item.CountryID}>{item.CountryName}</option>))}
                        </select>
                    </div>
                    <div class="">
                        <label htmlFor="EmergingIssue" className="block text-sm font-medium text-white">Emerging Issue</label>
                        <select disabled={loading} name="EmergingIssue" id="EmergingIssueID" value={emergingIssueFlagSelection} onChange={(e) => {setEmergingIssueFlagSelection(e.target.value); console.log(e.target.value)}} className="my-2 focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md">
                            <option value=''>Choose one</option>
                            {emergingIssueData.map(item => (<option key={item.EmergingIssueID} value={item.EmergingIssueID}>{item.EmergingIssueFlag}</option>))}
                        </select>
                    </div>
                </div>
                <button type="submit" class="p-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Search</button>
                <button class="p-2 mx-8 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" onClick={props.updateModalState}>Cancel</button>
            </form>
            </div>
        </div>);};

export default Search;